<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs v-model="activeTab">
        <vs-tab :label="$t('practical_test.config')">
          <practical-test-config :id="id"></practical-test-config>
        </vs-tab>
        <vs-tab :label="$t('practical_test.teachers')" v-if="isEdit()">
          <practical-test-teacher :id="id"></practical-test-teacher>
        </vs-tab>
        <vs-tab :label="$t('practical_test.students')" v-if="isEdit()">
          <practical-test-student :id="id"></practical-test-student>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import PracticalTestService from '@/services/api/PracticalTestService'

import PracticalTestConfig from './PracticalTestConfig.vue'
import PracticalTestStudent from './PracticalTestStudent.vue'
import PracticalTestTeacher from './PracticalTestTeacher.vue'

export default {
  directives: {

  },
  components: {
    PracticalTestConfig,
    PracticalTestStudent,
    PracticalTestTeacher
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    activeTab: 0
  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {
    this.service = PracticalTestService.build(this.$vs)
  },

  methods: {
    cancel() {
      this.$router.push('/practical_tests')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit(){
      return !_.isNil(this.id)
    },
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>

</style>
