<template>
  <div>
    <div class="vx-row mt-2">
      <div class="vx-col sm:w-1/1 w-full mb-2">
         <div class="w-full">
          <vs-input
            id="teacher_name"
            class="w-full required"
            v-validate="'required|max:191'"
            :label="$t('practical_test.teacher.name')"
            v-model="model.name"/>
          <span class="text-danger text-sm" v-show="errors.has('teacher_name')">{{errors.first('teacher_name')}}</span>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-2 gap-4 pt-3">
            <div>
              <vs-input
                id="teacher_email"
                class="w-full required"
                v-validate="'required|max:32'"
                :label="$t('practical_test.teacher.email')"
                v-model="model.email"/>
              <span class="text-danger text-sm" v-show="errors.has('teacher_email')">{{errors.first('teacher_email')}}</span>
            </div>
            <div>
              <vs-input
                  id="teacher_password"
                  class="w-full"
                  v-validate="'max:16'"
                  :label="$t('practical_test.teacher.password')"
                  v-model="model.password"/>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-2 gap-4 pt-3">
            <div>
               <vs-input
                  id="teacher_identifier"
                  class="w-full"
                  v-validate="'max:16'"
                  :label="$t('practical_test.teacher.identifier')"
                  v-model="model.identifier"/>
                <span class="text-danger text-sm" v-show="errors.has('teacher_identifier')">{{errors.first('teacher_identifier')}}</span>
            </div>
            <div>
               <vs-input
                  id="teacher_document"
                  class="w-full"
                  v-validate="'max:16'"
                  :label="$t('practical_test.teacher.document')"
                  v-model="model.document"/>
                <span class="text-danger text-sm" v-show="errors.has('teacher_document')">{{errors.first('teacher_document')}}</span>
            </div>
          </div>
        </div>
        <div class="flex mt-4 mb-4 justify-end">
           <vs-button
              v-permission="'practical_tests.teacher.add'"
              :disabled="!validateForm"
              type="filled"
              icon="add"
              @click="addNewParticipant()">{{ $t('adicionar') }}
            </vs-button>
           <vs-button
            v-permission="'practical_tests.teacher.add'"
              type="border"
              icon-pack="feather"
              icon="icon-file-plus"
              class="float-right ml-4 mr-4"
              @click="showParticipantImportPopup = true">
                {{ $t('importar') }}
            </vs-button>
            <vs-button
              type="border"
              @click="cancel">
              {{ $t('common.back') }}
            </vs-button>
        </div>
      </div>
    </div>
    <vs-divider class="mt-4">{{ $t('practical_test.teacherAdded') }}</vs-divider>
    <div class="grid grid-cols-1 gap-4 pt-4">
      <Grid
        ref="grid-participants"
        :service="service"
        :route_grid_path="`${id}/teachers`"
        route_name="teachers"
        :multipleSelect="true"
        @changedSelection="changedSelection">
        <template v-slot:actions="actions">
          <feather-icon
            v-permission="'practical_tests.teacher.delete'"
            icon="Trash2Icon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
            @click="() => confirmRemoveParticipantFromGrid(actions.row)"/>
        </template>
      </Grid>
    </div>
     <div class="vx-row mt-3">
      <div class="vx-col w-full">
        <vs-button
          v-permission="'practical_tests.teacher.delete'"
          class="float-right"
          color="primary"
          @click="confirmDeleteParticipants"
          v-if="enableActionDeleteParticipants">
          {{ $t('action.delete') }}
        </vs-button>
      </div>
    </div>
    <!-- POPUP -->
    <vs-popup
      :title="$t('practical_test.importTeachers')"
      :active.sync="showParticipantImportPopup"
      v-esc="closeImportPopup"
      style="overflow-y: auto; z-index: 54000;">
      <div class="flex flex-wrap items-center">
        <ImportExcel
          :onSuccess="onParticipantSuccess"
          templateFile="educatena-modelo-importacao-participantes.xlsx"
          :templateMessage="$t('clique-aqui-baixar-modelo-participantes')"
          accept=".xlsx, .xls">
        </ImportExcel>
      </div>
    </vs-popup>
    <!-- -->
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ImportExcel from '@/components/excel/ImportExcel'
import { getTextReadableTime } from '@/util/Util'

import PracticalTestService from '@/services/api/PracticalTestService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ImportExcel,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    showParticipantImportPopup: false,
    selectedParticipants : [],
    participantId : null,
    model: {
      id: null,
      description: ''
    },
    questionnaire: {
      id : null,
      name: ''
    },
  }),
  computed: {
    validateForm() {
       return !this.isEmpty(_.get(this.model, 'name'))
              && !this.isEmpty(_.get(this.model, 'email'))
    },
    enableActionDeleteParticipants() {
      return this.selectedParticipants.length > 0
    }
  },
  beforeMount() {
    this.service = PracticalTestService.build(this.$vs)
  },
  mounted() {

  },
  methods: {
    loadData(id) {
      this.$vs.loading()
    },
    cancel() {
      this.$router.push('/practical_tests')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit(){
      return !_.isNil(this.id)
    },
    addNewParticipant() {
      this.$vs.loading()

      this.service.addTeachers(this.id, {
        imports: [this.model]
      }).then(this.processImportResponse)
        .finally(this.finishImport)
    },
    onParticipantSuccess(data) {
      if (data && data.results && data.results.length > 0) {
        this.$vs.loading()
        this.service.addTeachers(this.id, {
          imports: data.results
        }).then(this.processImportResponse)
          .finally(this.finishImport)
      } else {
        this.notifyWarning(this.$vs, this.$t('nenhum-participante-encontrado-para-importar'))
      }

      this.showParticipantImportPopup = false
    },
    processImportResponse(response) {
      const messages = _.get(response, 'messages', []) || []
      if (messages.length > 0) {
        messages.forEach((message) => {
          this.notifyWarning(this.$vs, message, getTextReadableTime(message))
        })
      }
      const participants = _.get(response, 'participants', []) || []
      const message = this.$t('participants-length-importados-com-sucesso', [participants.length])
      this.notifySuccess(this.$vs, message, Math.floor(getTextReadableTime(message) * 1.5))
    },
    finishImport(response) {
      this.$vs.loading.close()
      this.clearImportFields()
      this.reloadGrid()
    },
    clearImportFields() {
      this.selectedParticipants = []
      this.model.name = ''
      this.model.email = ''
      this.model.document = ''
      this.model.identifier = ''
      this.model.password = ''
    },
    reloadGrid() {
      const grid = this.$refs['grid-participants']
      grid.fetchGridData()
    },
    closeImportPopup() {
      this.showParticipantImportPopup = false
    },
    changedSelection(items) {
      this.selectedParticipants = items
    },
    removeParticipantFromGrid() {
      const participantIds = [this.participantId]
      this.removeParticipant(participantIds)
    },
    removeParticipant(participantIds) {
      this.$vs.loading()
      this.service.removeTeacher(this.id, participantIds).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })
          this.reloadGrid()
          this.clearImportFields()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    confirmRemoveParticipantFromGrid(data) {
      this.participantId = data.id
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.removeParticipantFromGrid,
      })
    },
    confirmDeleteParticipants() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.deleteParticipants,
      })
    },
    deleteParticipants() {
      this.removeParticipant(this.selectedParticipants)
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>

</style>
