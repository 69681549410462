<template>
  <div>
    <div class="vx-row mt-2">
        <div class="vx-col sm:w-1/1 w-full mb-2">
          <div class="w-full">
            <select-suggestion
              :label="$t('Questionnaire')"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              column="name,description"
              model="ContentQuestionnaire"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"/>
            <span
                class="text-danger text-sm"
                v-show="errors.has('questionnaire_id')">{{ errors.first('questionnaire_id') }}</span>
          </div>
          <div class="w-full mt-2">
            <vs-textarea
              id="description"
              :label="$t('practical_test.description')"
              v-model="model.description" />
            <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
          </div>
        </div>
        <div class="w-full flex gap-2 justify-end">
          <vs-button
            v-if="!isEdit()"
            :disabled="!validateForm"
            v-permission="'practical_tests.create'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>
          <vs-button
            v-if="isEdit()"
            :disabled="!validateForm"
            v-permission="'practical_tests.edit'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>

          <vs-button
            type="border"
            @click="cancel">
            {{ $t('common.back') }}
          </vs-button>
        </div>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import PracticalTestService from '@/services/api/PracticalTestService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      description: ''
    },
    questionnaire: null,
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(_.get(this.questionnaire, 'id'))
    }
  },
  beforeMount() {

  },
  mounted() {
    this.service = PracticalTestService.build(this.$vs)
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
          this.questionnaire = {id : response.questionnaire_id, name: response.questionnaire_name}
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        description: this.model.description,
        questionnaire_id: this.questionnaire.id
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$router.push(`/practical_tests/${data.id}/edit`)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => {this.$vs.loading.close()}
      )
    },
    cancel() {
      this.$router.push('/practical_tests')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>

</style>
