var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row mt-2" }, [
      _c("div", { staticClass: "vx-col sm:w-1/1 w-full mb-2" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c("select-suggestion", {
              ref: "select_questionnaire",
              class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
              attrs: {
                label: _vm.$t("Questionnaire"),
                column: "name,description",
                model: "ContentQuestionnaire",
                appendClearOption: true,
                placeholderText: "Digite o nome do questionário",
              },
              model: {
                value: _vm.questionnaire,
                callback: function ($$v) {
                  _vm.questionnaire = $$v
                },
                expression: "questionnaire",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("questionnaire_id"),
                    expression: "errors.has('questionnaire_id')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mt-2" },
          [
            _c("vs-textarea", {
              attrs: {
                id: "description",
                label: _vm.$t("practical_test.description"),
              },
              model: {
                value: _vm.model.description,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "description", $$v)
                },
                expression: "model.description",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("description")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "w-full flex gap-2 justify-end" },
        [
          !_vm.isEdit()
            ? _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "practical_tests.create",
                      expression: "'practical_tests.create'",
                    },
                  ],
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.createOrUpdate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              )
            : _vm._e(),
          _vm.isEdit()
            ? _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "practical_tests.edit",
                      expression: "'practical_tests.edit'",
                    },
                  ],
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.createOrUpdate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              )
            : _vm._e(),
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }