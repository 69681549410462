var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row mt-2" }, [
        _c("div", { staticClass: "vx-col sm:w-1/1 w-full mb-2" }, [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:191",
                    expression: "'required|max:191'",
                  },
                ],
                staticClass: "w-full required",
                attrs: {
                  id: "student_name",
                  label: _vm.$t("practical_test.student.name"),
                },
                model: {
                  value: _vm.model.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("student_name"),
                      expression: "errors.has('student_name')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("student_name")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:32",
                    expression: "'required|max:32'",
                  },
                ],
                staticClass: "w-full required",
                attrs: {
                  id: "student_email",
                  label: _vm.$t("practical_test.student.email"),
                },
                model: {
                  value: _vm.model.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("student_email"),
                      expression: "errors.has('student_email')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("student_email")))]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "grid grid-cols-2 gap-4 pt-3" }, [
              _c(
                "div",
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:16",
                        expression: "'required|max:16'",
                      },
                    ],
                    staticClass: "w-full required",
                    attrs: {
                      id: "student_identifier",
                      label: _vm.$t("practical_test.student.identifier"),
                    },
                    model: {
                      value: _vm.model.identifier,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "identifier", $$v)
                      },
                      expression: "model.identifier",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("student_identifier"),
                          expression: "errors.has('student_identifier')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("student_identifier")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:16",
                        expression: "'max:16'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      id: "student_document",
                      label: _vm.$t("practical_test.student.document"),
                    },
                    model: {
                      value: _vm.model.document,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "document", $$v)
                      },
                      expression: "model.document",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("student_document"),
                          expression: "errors.has('student_document')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("student_document")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex mt-4 mb-4 justify-end" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    disabled: !_vm.validateForm,
                    type: "filled",
                    icon: "add",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewParticipant()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("adicionar")) + " ")]
              ),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "practical_tests.student.add",
                      expression: "'practical_tests.student.add'",
                    },
                  ],
                  staticClass: "float-right ml-4 mr-4",
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-file-plus",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showParticipantImportPopup = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("importar")) + " ")]
              ),
              _c(
                "vs-button",
                { attrs: { type: "border" }, on: { click: _vm.cancel } },
                [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("vs-divider", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("participantes-adicionados"))),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 pt-4" },
        [
          _c("Grid", {
            ref: "grid-participants",
            attrs: {
              service: _vm.service,
              route_grid_path: _vm.id + "/students",
              route_name: "students",
              multipleSelect: true,
            },
            on: { changedSelection: _vm.changedSelection },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (actions) {
                  return [
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "practical_tests.student.delete",
                          expression: "'practical_tests.student.delete'",
                        },
                      ],
                      attrs: {
                        icon: "Trash2Icon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                      },
                      on: {
                        click: function () {
                          return _vm.confirmRemoveParticipantFromGrid(
                            actions.row
                          )
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row mt-3" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _vm.enableActionDeleteParticipants
              ? _c(
                  "vs-button",
                  {
                    staticClass: "float-right",
                    attrs: { color: "primary" },
                    on: { click: _vm.confirmDeleteParticipants },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("action.delete")) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          directives: [
            {
              name: "esc",
              rawName: "v-esc",
              value: _vm.closeImportPopup,
              expression: "closeImportPopup",
            },
          ],
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: _vm.$t("practical_test.importStudents"),
            active: _vm.showParticipantImportPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showParticipantImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c("ImportExcel", {
                attrs: {
                  onSuccess: _vm.onParticipantSuccess,
                  templateFile:
                    "educatena-modelo-importacao-participantes.xlsx",
                  templateMessage: _vm.$t(
                    "clique-aqui-baixar-modelo-participantes"
                  ),
                  accept: ".xlsx, .xls",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }